.Card {
    /* Stake */
    box-sizing: border-box;

    align-content: flex-start;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 42px;
    gap: 24px;

    width: 90%;
    height: fit-content;
    max-height: 660px;

    background: #393a77;
    border-radius: 16px;
    padding: 5px 20px;
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: 8px;
    scrollbar-color: #7273c0 #43447e;
}

.Card::-webkit-scrollbar {
    width: 18px;
    background-color: #43447e;
    border-radius: 8px;
}

.Card::-webkit-scrollbar-thumb {
    background-color: #7273c0;
    border-radius: 8px;
}

.Card::-webkit-scrollbar-track {
    background-color: #43447e;
    border-radius: 8px;
}

.Card-title {
    text-align: center;
}

.Card-title-text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
}

.Card-label {
    display: inline-block;
    vertical-align: middle;
    border-radius: 100px;
    background: #b9baff;
    padding: 0.1rem 1rem;
    color: #3437f3;
}

#grp-btn-selected {
    color: #b9baff;
    margin-right: 10px;
    padding: 5px 8px;
    border-radius: 100px;
    font-size: 16px;
    border: 1px solid #b9baff;
}

#grp-btn {
    font-weight: 500;
    color: #8181b5;
    margin-right: 10px;
    padding: 5px 8px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent; /* Add a transparent border */
}

h1 {
    padding: 0px 0px;
    color: white;
}

p {
    color: white;
}

.parent-container-card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2px;
    width: 100%;
}

input,
textarea {
    background-color: rgba(0, 0, 0, 0.2);

    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border-bottom: none;
    border-radius: 10px;
    font-family: Rubik;
    color: white;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding: 10px 10px;
    width: 100%;
}

.input-container {
    position: relative;
    float: left;
    width: 90%;
}

.input-container:after {
    position: absolute;
    right: 0;
    top: 0;
    content: "PARTY";

    font-family: Rubik;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    margin-right: 10px;
    margin-top: 15px;
    font-size: 15px;
}

.amount-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
    gap: 10px;
}

.available {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* font-size: 12px;
    line-height: 15px; */
    color: #8181b5;
    margin: 0;
}

.max-button {
    font-family: "Inter";
    color: #7d7fe5;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    /* margin: 0px; */
    /* font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 15px;
color: #7D7FE5; */
}

/* Stake Info */
.user-stake-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.user-stake-info-label {
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.9;
}

.user-stake-info-value {
    text-align: right;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
}

.user-stake-info-value:last-child {
    text-align: right;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: gold;
}

/* Stake-ing Button */
.stake-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 30px;
    gap: 10px;

    width: 100%;

    background: linear-gradient(267.98deg, #5c99f5 23.46%, #397bde 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
        inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin: 20px 0px;
}

.stake-button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3D7EE1;
    box-shadow: none;
}

.stake-button-pool-c {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 30px;
    gap: 10px;

    width: 100%;

    background: linear-gradient(267.98deg, #F5B85C 23.46%, #DEB039 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
        inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin: 20px 0px;
}

.unstake-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 30px;
    gap: 10px;

    width: 100%;

    background: linear-gradient(267.98deg, #e45cf5 23.46%, #cc39de 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
        inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin: 20px 0px;
}

.unstake-button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D445E6;
    box-shadow: none;
}

.locked-button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;


    width: 100%;

    background-image: linear-gradient(269.59deg, rgba(179, 179, 179) 1.57%, rgba(91, 91, 91) 97.79%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2), inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: none;
    cursor: not-allowed;
    margin: 20px 0px;

    color: #FFFFFF;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 900;
}

.locked-button .button-text {
    position: relative;
    z-index: 1;
    
}

.locked-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../assets/images/pinstripe.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
}



/* Pool Stake info */
.stake-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 11px;
    width: 100%;
    margin: 20px 0px;
}

.stake-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 11px;
}

.stake-info-label {
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.9;
    margin: 0px;
}

.stake-info-value {
    text-align: right;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    margin: 0px;
}

/*#region Favor Card */
.favor-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 42px;
    gap: 24px;
    width: 90%;

    background: #393a77;
    border-radius: 16px;
    padding: 5px 20px;
}

.favor-meter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.favor-meter-ring {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.favor-info {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    width: 100%;
    margin: 10px 0px;
}

.favor-info-container {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.favor-stake-info-label {
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.9;
}

/*#endregion */

/*#region Activity Card */

.activity-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 42px;
    gap: 24px;

    width: 90%;

    background: #393a77;
    border-radius: 16px;
    padding: 5px 20px;
}

.activity-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    border-radius: 8px;
    padding: 20px 20px;
}

.item-left {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    gap: 4px;
    width: 33%;
}

.item-center {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 33%;
}

.center-card-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 4px;
}

.item-right {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    gap: 4px;
    width: 33%;
}

.activity-card:hover {
    transform: scale(1.01);
}

.activity-card-title-text {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin: 0;
}

.activity-card-sub-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.5;
    margin: 0;
}

/*#endregion */

/*#region Buy Card */
.Buy-Box {
    margin: 30px 10px;
    min-height: 25vh;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(267.98deg, #e45cf5 23.46%, #cc39de 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
        inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.Candy-Box{
    background-image: url('../../assets/images/Candy.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.Buy-Box-Text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    height: 25vh;
    padding: 10px;
    gap: 24px;
}

.Buy-Box-Title-Div{
    height: 15vh;
     display: flex;
    justify-content: center;
    align-items: flex-start;
}

.Buy-Box-Text-Div{
    height: 15vh;
     display: flex;
    justify-content: center;
    align-items: flex-end;
}

.Buy-Box-Title {
    font-family: "Inter";
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    color: #ffffff;
    margin: 0px;

}

.Buy-Box-Text {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    margin: 0px;
}

.Buy-Box-Label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    gap: 16px;
}

.Favor-Input-Label-Text {
    text-align: left;
}

.Buy-Box-Input-container {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
}
.Buy-Box-Input-Wrapper {
    width: 55%;
}

.Total-Input-Wrapper {
    width: calc(67% - 8px);
}

.total-container {
    position: relative;
    float: left;
}

.total-container:after {
    position: absolute;
    right: 0;
    top: 0;
    content: "PARTY";

    font-family: Rubik;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    margin-right: 10px;
    margin-top: 15px;
    font-size: 15px;
}


.Buy-Box-Input {
    width: calc(100% - 24px);
    padding: 8px 8px;
    background-color: #393a77;
    color: #ffffff;
    border: 1px solid #43447e;
    border-radius: 8px;
    outline: none;
}

.Favor-Buy-Box-Input {
    padding: 8px 16px;
    background-color: #393a77;
    color: #ffffff;
    border: 1px solid #43447e;
    border-radius: 8px;
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.Favor-Input-Container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #393a77;
    border: 1px solid #43447e;
    height: 48px;
    border-radius: 8px;
}


.Favor-Input {
    width: calc(100% - 0px);
    height: 28px;
    text-align: center;
    background-color: transparent;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    outline: none;
    input-security: disc;
}

.Favor-Input-Button {
    background-color: #393a77;
    color: #ffffff;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    cursor: pointer;
}

.Favor-Input-Button.Minus {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    left: 0px;
}

.Favor-Input-Button.Plus {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    right: 0px;
}

.Purchase-Button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 30px;
    gap: 10px;

    width: 100%;

    background: linear-gradient(267.98deg, #e45cf5 23.46%, #cc39de 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
        inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin: 20px 0px;
}

.Purchase-Button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D445E6;
    box-shadow: None;
}
  

/*#endregion */


/*#region Pool C */

.Party-Input-Label {
    pointer-events: none;
    font-family: Rubik;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    margin-right: 10px;
    margin-top: 15px;
    font-size: 15px;
}

/* Add this to your Card.css */
.input-error {
    border: 1px solid #FF7474;
  }
  
.no-outline:focus {
    outline: none;
}


/*#endregion */











