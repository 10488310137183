.progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #393a77;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    transition: all 0.3s ease-in-out;
}

  
.progress-label {
  font-size: 24px;
  font-weight: bold;
  color: #f5f5f5;
}

.progress-wrapper {
  flex-grow: 1;
  margin: 0 16px;
  position: relative;
  overflow: hidden;
}

.progress-svg {
  width: 100%;
  height: auto;
  fill: none;
  z-index: 1;
  transform: translateY(20%);
}

.progress {
  transition: stroke-dasharray 0.3s ease-in-out;
  background-color: #fff;
  fill: #393a77;
  width: 100%;
}

.percentage {
  font-size: 14px;
  font-weight: bold;
  fill: #fff;
}

.progress-text__percentage,
.progress-text__label {
  fill: #fff;
}

.progress-text__percentage {
    font-size: 2.5rem;
    font-weight: bold;
    color: #f5f5f5;
}

.progress-text__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 85%;
}

.progress-text__label {
    font-size: 1rem;
    font-weight: bold;
    color: #f5f5f5;
    width: fit-content;
}
