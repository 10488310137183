.Card {
    box-sizing: border-box;
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 31px 27px;
    gap: 10px;
    width: 90%;
    background: #393A77;
    border-radius: 16px;
    margin: 1rem;
    padding: 5px 20px;
    position: relative;
}

.Card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, var(--border-color, #FFEBA3) 51.56%, rgba(0, 0, 0, 0) 100%);
    border-radius: 16px 16px 0 0;
}

.stakes-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    width: 100%;
}

.stakes-card-item:last-child {
    margin-bottom:14px;
}

.stakes-card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;    
    background: #43447E;
    border-radius: 8px;
    padding: 0px 17px;
    width: 100%;
    height: 70px;
    position: relative;
}

.stakes-card-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    background: linear-gradient(#65AAEC 0%, #65AAEC 100%);
    border-radius: 8px 0 0 8px;
}

.stakes-card-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 76px;
    padding: 0px 10px;
}

.stakes-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin: 0px;
    width: 150px;
}

.stakes-text {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}

.stakes-text-apy {
    color: gold;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}

.stakes-text-faded {
    font-size: 14px;
    color: #B9BAFF;
    font-weight: 500;
    margin: 0px;
}

/* Buttons */

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.stakes-unstake-button {
    width: 137px;
    height: 35px;
    cursor: pointer;
    
    /* locked */
    
    /* background: linear-gradient(269.59deg, #B3B3B3 1.57%, #5B5B5B 97.79%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2), inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px; */

    /* pink */
    background: linear-gradient(267.98deg, #E45CF5 23.46%, #CC39DE 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2), inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;

    /* locked */
    background-size: cover;
    padding: 0;
    border: none;

    /* Inside auto layout */
    
    flex: none;
    order: 2;
    flex-grow: 0;    


    font-family: 'Rubik';
    font-style: normal;
    font-weight: 1000;
    font-size: 14px;
    color: #FFFFFF;
}

.stakes-unstake-button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D445E6;
    box-shadow: none;
}

.stakes-contribute-button {
    width: 159px;
    height: 35px;
    cursor: pointer;
    
    /* green grad 2 */
    
    background: linear-gradient(267.98deg, #0BA788 23.46%, #0AC075 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2), inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0;
    border: none;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 1000;
    font-size: 14px;
    color: #FFFFFF;
}

.stakes-contribute-button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0BBD78;
    box-shadow: none;
}

.stakes-jump-button {
    width: 137px;
    height: 35px;
    cursor: pointer;
    
    background: linear-gradient(267.98deg, #5C90F5 23.46%, #3985DE 82.64%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2), inset -4px 4px 10px rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    padding: 0;
    border: none;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 1000;
    font-size: 14px;
    color: #FFFFFF;
}

.stakes-jump-button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3D7EE1;
    box-shadow: none;
}

