.EpochProgress {
    display: flex;
    background: #393A77;
    border-radius: 100px;   
    width: 90%;
    height: 52px;
    position: relative;
}

.EpochProgress-bar {
    background: #43447E;
    border-radius: 100px;
    height: 52px;
    width: 100%;
    position: relative;
    z-index: 1;
}

@media(max-width: 600px){
    .EpochProgress-label{
        display: revert !important;
        text-align: left;
        margin-left: -10px;
    }
}

.EpochProgress-bar::before {
    content: "";
    position: absolute;
    top: -5px;
    bottom: -5px;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.3);
}

.EpochProgress-bar::before {
    left: 33%;
    top: -33%;
    bottom: -33%;
}

.EpochProgress-bar::after {
    left: 66%;
    top: -33%;
    bottom: -33%;
}

.EpochProgress-bar-inner {
    background: linear-gradient(269.59deg, #9780F0 1.57%, #C47DF3 48.67%, #CC6DF6 97.79%);
    box-shadow: 0px 4px 80px 70px rgba(205, 66, 227, 0.06);
    border-radius: 100px 100px 100px 100px;
    height: 52px;
}

.EpochProgress-bar-inner-full {
    background-color: #0BBD78;
    box-shadow: 0px 4px 80px 70px rgba(205, 66, 227, 0.06);
    border-radius: 100px 100px 100px 100px;
    height: 52px;
}


.EpochProgress-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    padding: 0 16px;
}

.EpochProgress-label {
    color: white;
    width: 33.333%;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
}

.EpochProgress-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}