::-webkit-scrollbar-corner { display: none; }

body {
  background-color: #1D1E3D;
}

.App {
  text-align: center;
  background-color: #1D1E3D;
  overflow: hidden;
}

.App-logo {
  pointer-events: none;
  width: 254px;
}

@media(max-width: 1200px){
  .App-logo{
    width: 30vw;
  }
  .App-logo-div{
    margin-left: 5px !important;
  }
  .connect-button{
    width: 50vw !important;
    margin-left: 20px;
    margin-top: 15px;
  }

}

 .mobileDiv{
display: flex;
}



.App-logo-div {
  display: flex;
  margin-left: 50px;
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
}

#party-balance {
padding: 5px 17px;
gap: 10px;

background: #393A77;
border-radius: 8px;

font-size: 20px;
margin-top: 5px;
margin-right: 10px;

}

#favor-balance {
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px 17px;
  gap: 10px;
  
  border-radius: 8px;
  
  font-size: 20px;
  background: #396877;
}

@media (max-width: 1520px) {
  .stakes-card {
    display: none !important;
  }  
}

.stakes-parent-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}





.parent-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
}

.parent-container-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px; 
}


.child {
  grid-column: span 1;
  grid-row: span 1;
}

.connect-button {
  text-align: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 1000;
  font-size: 14px;
  color: #FFFFFF;
  padding: 8px 16px;
  gap: 16px;
  width: 162px;
  height: 37px;
  background: linear-gradient(267.98deg, #23CE91 23.46%, #23AD4A 82.64%);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
  margin-right: 60px;
}

.connected-button {
  text-align: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 1000;
  font-size: 14px;
  color: #FFFFFF;
  padding: 8px 16px;
  gap: 16px;
  width: 162px;
  height: 37px;
  background: linear-gradient(267.98deg, #23CE91 23.46%, #23AD4A 82.64%);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
  margin-right: 60px;
}


/*#region Footer  */

.footer {
  display: flex;
  flex-direction: row;
  color: white;
  padding: 0px 61px;
  align-items: center;
  justify-content: space-between;
  background: #43447E;
  height: 84px;
}

.footer-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media(max-width: 600px){
  .footer-left{display: grid !important; gap: 10px !important; margin-right: 20px;}
  .footer{display: flex; justify-content: center;}
  .footer-button:first-child{margin-top: 0px;}
  .footer-button{width: 50vw !important;}
}

.footer-sound-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footer-button {  
  width: 145px;
  height: 31px;
  background: linear-gradient(267.98deg, #0BA788 23.46%, #0AC075 82.64%);
  border-radius: 8px;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  cursor: pointer;
  border: none;
}

.footer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.footer-icon {
  cursor: pointer;
}

/*#endregion  */


/*#region Mobile Menu  */
.hamburger {
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #43447E;
  height: 100vh;
  width: 75vw;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}


.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

/*#endregion  */

/* Media query for mobile devices */
@media screen and (max-width: 1440px) {
  .parent-container {
    grid-template-columns: repeat(2, 1fr); /* Change to a single column layout for smaller screens */
  }
}


/* Media query for mobile devices */
@media screen and (max-width: 950px) {
  .parent-grid-container {
    grid-template-columns: repeat(1, 1fr); /* Change to a single column layout for smaller screens */
  }
  .parent-container-header {
    grid-template-columns: repeat(1, 1fr); /* Change to a single column layout for smaller screens */
  }
}

button {
  color: #ffffff !important;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}